<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>

    <app-project-implementation-form
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      :allReleasedCC="allReleasedCC"
      :allReleasedEC="allReleasedEC"
      :anyToRequestReleaseCC="anyToRequestReleaseCC"
      :anyToRequestReleaseEC="anyToRequestReleaseEC"
      :isEthicsCommitteeEnumSet="isEthicsCommitteeEnumSet"
      :isCooperationContractsEnumSet="isCooperationContractsEnumSet"
      :isEmploymentContractsEnumSet="isEmploymentContractsEnumSet"
      @requestReleaseCoopPartnerContracts="
        doCooperationPartnerContractsReleaseRequest
      "
      @requestReleaseEmploymentContracts="
        doEmploymentContractsReleaseRequest
      "
      @submit="doSubmit"
      v-if="isDataReady"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectImplementationForm from '@/modules/project-implementation/components/project-implementation-form';
import Errors from '@/shared/error/errors';
import { EventBus } from '@/eventBus';
import Message from '@/shared/message/message';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';
import ProjectStepEnum from '@/shared/enums/projectStep';

export default {
  name: 'app-project-implementation-form-project-page',

  components: {
    [ProjectImplementationForm.name]: ProjectImplementationForm,
  },

  data() {
    return {
      projectStepEnum: ProjectStepEnum.values,
    };
  },

  computed: {
    ...mapGetters({
      record: 'projectImplementation/form/record',
      findLoading: 'projectImplementation/form/findLoading',
      saveLoading: 'projectImplementation/form/saveLoading',
      pendingFiles: 'projectImplementation/form/pendingFiles',
      status: 'projectImplementation/form/status',
      isEthicsCommitteeEnumSet:
        'projectImplementation/form/isEthicsCommitteeEnumSet',
      isCooperationContractsEnumSet:
        'projectImplementation/form/isCooperationContractsEnumSet',
      isEmploymentContractsEnumSet:
        'projectImplementation/form/isEmploymentContractsEnumSet',
      projectId: 'project/form/projectId',
      projectImplementationId: 'project/form/projectImplementationId',
      allReleasedCC: 'cooperationPartnerContract/list/allReleased',
      anyToRequestReleaseCC:
        'cooperationPartnerContract/list/anyToRequestRelease',
      allReleasedEC: 'employmentContract/list/allReleased',
      anyToRequestReleaseEC:
        'employmentContract/list/anyToRequestRelease',
    }),

    isDataReady() {
      return this.record && !this.findLoading;
    },

    isEditing() {
      return !!this.projectImplementationId;
    },

    model: {
      get: function () {
        return this.projectImplementationId;
      },

      set: function (id) {
        this.$emit('input', id);
      },
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.projectImplementationId);
    } else {
      await this.doNew();
      await this.doSubmit({
        id: undefined,
        values: {
          cooperationPartnerContracts: [],
          employmentContracts: [],
          ethicsCommitteeDecision: [],
        },
      });
    }

    await this.doFilterCooperationPartnerList();
    await this.doFilterEmploymentContractsList();

    EventBus.$on(
      'updateCooperationPartenerContractsList',
      (payload) => {
        this.doFilterCooperationPartnerList(payload);
      },
    );

    EventBus.$on('updateEmploymentContractsList', (payload) => {
      this.doFilterEmploymentContractsList(payload);
    });
  },

  beforeDestroy() {
    EventBus.$off('updateCooperationPartenerContractsList');
    EventBus.$off('updateEmploymentContractsList');
  },

  async beforeRouteLeave(to, from, next) {
    //if status is completed we skip the dialog check and navigate to next page
    if (this.status === 'completed' || to.name.includes('error')) {
      next();
      return;
    }

    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doFind: 'projectImplementation/form/doFind',
      doNew: 'projectImplementation/form/doNew',
      doUpdate: 'projectImplementation/form/doUpdate',
      doCreate: 'projectImplementation/form/doCreate',
      doFetchCooperationPartner:
        'cooperationPartnerContract/list/doFetch',
      doFetchEmploymentContracts: 'employmentContract/list/doFetch',
      doCoopPartnerContractsReleaseRequest:
        'cooperationPartnerContract/list/doReleaseRequest',
      doEmplContractsReleaseRequest:
        'employmentContract/list/doReleaseRequest',
      doUpdateProject: 'project/form/doUpdate',
      doSetProjectImplementationId:
        'project/form/doSetProjectImplementationId',
      doUpdateProjectStep: 'project/form/doUpdateProjectStep',
      doReloadProject: 'project/form/doReload',
    }),

    async doSubmit(payload) {
      try {
        if (this.isEditing) {
          const response = await this.doUpdate(payload);
          if (response) {
            if (response.status === 'completed') {
              /*               await this.doUpdateProjectStep(
                this.projectStepEnum.reports,
              ); */
              await this.navigateToProjectReport();
              await this.doReloadProject();
            }
          }
        } else {
          const id = await this.doCreate(payload.values);
          await this.doSetProjectImplementationId(id);
          await this.doUpdateProject();
        }
      } catch (error) {
        Errors.handle(error);
      }
    },

    async doFilterCooperationPartnerList(payload) {
      const filter = this.setFilter(payload);

      return this.doFetchCooperationPartner({
        filter,
      });
    },

    async doFilterEmploymentContractsList(payload) {
      const filter = this.setFilter(payload);

      return this.doFetchEmploymentContracts({
        filter,
      });
    },

    setFilter(payload) {
      let id;
      if (payload != undefined || payload != null) {
        id = payload.map(function (f) {
          return f.id;
        });
      }

      return {
        projectImplementation: this.projectImplementationId,
        id,
      };
    },

    async doCooperationPartnerContractsReleaseRequest(payload) {
      if (payload.values && payload.values.length === 0) {
        Message.warning(
          i18n(
            'entities.projectImplementation.releaseCoopPartnerContracts.warning',
          ),
        );
        return;
      }
      await this.doCoopPartnerContractsReleaseRequest(payload);
      this.doFilterCooperationPartnerList();
    },

    async doEmploymentContractsReleaseRequest(payload) {
      if (payload.values && payload.values.length === 0) {
        Message.warning(
          i18n(
            'entities.projectImplementation.releaseEmploymentContracts.warning',
          ),
        );
        return;
      }
      await this.doEmplContractsReleaseRequest(payload);
      this.doFilterEmploymentContractsList();
    },

    navigateToProjectReport() {
      EventBus.$emit('navigateToProjectReport');
    },
  },
};
</script>

<style>
</style>
