/**
 * List of project create statuses.
 */
class ImplementationStatus {
    static get values() {
      return { 
        ethicsCommittee: 'ethicsCommittee',
        cooperationPartnerContracts: 'cooperationPartnerContracts',
        employmentContracts: 'employmentContracts',        
        grants: 'grants',
        completed: 'completed',
      };
    }
  }
  
  module.exports = ImplementationStatus;