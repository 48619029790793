<template>
  <div>
    <form-wizard
      @on-complete="doSubmitFinish"
      :start-index="getWizardStartIndex"
      color="#393939"
      error-color="#ff4949"
      step-size="xs"
      ref="formWizard"
    >
      <h2 slot="title"></h2>
      <tab-content
        icon="ti-user"
        title="Ethics Committee"
        :before-change="validateEthicsCommitte"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formEthicsCommittee"
          v-if="model"
          :disabled="isCompleted"
        >
          <el-form-item
            :label="fields.ethicsCommitteeEnum.label"
            :prop="fields.ethicsCommitteeEnum.name"
            :required="fields.ethicsCommitteeEnum.required"
          >
            <el-col :lg="3" :md="3" :sm="3">
              <el-select
                placeholder="Please select if evaluation by Ethics Committee is requred"
                v-model="model[fields.ethicsCommitteeEnum.name]"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.ethicsCommitteeEnum.options"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>

          <!-- Template to download for Ethics Committee Guidelines-->
          <app-template-view-file-page
            templateType="ethicsCommitteeGuidelines"
            v-if="ethicsCommitteeDecisionRequired"
          ></app-template-view-file-page>

          <el-form-item
            :label="fields.ethicsCommitteeDecision.label"
            :prop="fields.ethicsCommitteeDecision.name"
            :required="fields.ethicsCommitteeDecision.required"
            v-if="ethicsCommitteeDecisionRequired"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.ethicsCommitteeDecision.max"
                :storage="fields.ethicsCommitteeDecision.storage"
                :formats="fields.ethicsCommitteeDecision.formats"
                :permissions="
                  fields.ethicsCommitteeDecision.permissions
                "
                v-model="model[fields.ethicsCommitteeDecision.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        icon="ti-user"
        title="Cooperation Contracts"
        :before-change="validateCooperationPartners"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formCooperationPartners"
          v-if="model"
          :disabled="isCompleted"
        >
          <el-form-item
            :label="fields.cooperationContractsEnum.label"
            :prop="fields.cooperationContractsEnum.name"
            :required="fields.cooperationContractsEnum.required"
          >
            <el-col :lg="3" :md="3" :sm="3">
              <el-select
                placeholder
                v-model="model[fields.cooperationContractsEnum.name]"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.cooperationContractsEnum
                    .options"
                ></el-option>
              </el-select>
            </el-col>
            <el-col
              :lg="13"
              :md="16"
              :sm="24"
              v-if="cooperationPartnerContractRequired"
            >
              <app-cooperation-partner-contract-autocomplete-input
                :fetchFn="fields.cooperationPartnerContracts.fetchFn"
                :mapperFn="
                  fields.cooperationPartnerContracts.mapperFn
                "
                :parentId="model.id"
                :showSelectElement="false"
                :filterServerDataSourceBy="
                  filterCoopPartnerContractBy
                "
                :showCreate="!modal"
                mode="multiple"
                v-model="
                  model[fields.cooperationPartnerContracts.name]
                "
              ></app-cooperation-partner-contract-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-col :lg="13" :md="16" :sm="24">
            <!-- Template to download for Cooperation Partner Contract template-->
            <app-template-view-file-page
              templateType="cooperationAgreement"
              v-if="cooperationPartnerContractRequired"
            ></app-template-view-file-page>
          </el-col>

          <app-cooperation-partner-contract-list-table
            @removeCoopPartnerContract="removeCoopPartnerContract"
            v-if="cooperationPartnerContractRequired"
            :isInPojectImplementation="true"
          ></app-cooperation-partner-contract-list-table>
        </el-form>
      </tab-content>

      <tab-content
        icon="ti-user"
        title="Employment Contracts"
        :before-change="validateEmploymentContracts"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formEmploymentContracts"
          v-if="model"
          :disabled="isCompleted"
        >
          <el-form-item
            :label="fields.employmentContractsEnum.label"
            :prop="fields.employmentContractsEnum.name"
            :required="fields.employmentContractsEnum.required"
          >
            <el-col :lg="3" :md="3" :sm="3">
              <el-select
                placeholder
                v-model="model[fields.employmentContractsEnum.name]"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.employmentContractsEnum
                    .options"
                ></el-option>
              </el-select>
            </el-col>
            <el-col
              :lg="13"
              :md="16"
              :sm="24"
              v-if="employmentContractRequired"
            >
              <app-employment-contract-autocomplete-input
                :fetchFn="fields.employmentContracts.fetchFn"
                :mapperFn="fields.employmentContracts.mapperFn"
                :showCreate="!modal"
                :showSelectElement="false"
                :parentId="model.id"
                :filterServerDataSourceBy="filterEmploymentContractBy"
                mode="multiple"
                v-model="model[fields.employmentContracts.name]"
              ></app-employment-contract-autocomplete-input>
            </el-col>
          </el-form-item>

          <!-- Template to download for Employment Contract template-->
          <app-template-view-file-page
            templateType="employmentContract"
            v-if="employmentContractRequired"
          ></app-template-view-file-page>

          <app-employment-contract-list-table
            @removeEmploymentContract="removeEmploymentContract"
            v-if="employmentContractRequired"
            :isInPojectImplementation="true"
          ></app-employment-contract-list-table>
        </el-form>
      </tab-content>

      <!-- Wzard Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left wizard-btn">
          <wizard-button
            v-if="props.activeTabIndex > 0"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            ><app-i18n code="common.previous"></app-i18n
          ></wizard-button>
        </div>
        <div class="wizard-footer-right">
          <div
            class="wizard-btn"
            v-if="
              props.activeTabIndex === 1 || props.activeTabIndex === 2
            "
          >
            <wizard-button
              icon="el-icon-fa-floppy-o"
              @click.native="doRequestDraftsRelease"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              v-if="
                displayRequestRelease &&
                  !(isRequestBtnDisabled || isCompleted)
              "
              ><app-i18n
                code="common.requestContractsReview"
              ></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              v-if="!isCompleted"
              icon="el-icon-fa-floppy-o"
              @click.native="doSubmit"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.save"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              v-if="!props.isLastStep && !nextButtonDisabled"
              @click.native="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.next"></app-i18n
            ></wizard-button>
            <wizard-button
              @click.native="doSubmitFinish()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              v-if="
                props.isLastStep &&
                  !(
                    saveLoading ||
                    isCompleted ||
                    disabledFinishButton
                  )
              "
              ><app-i18n code="common.finish"></app-i18n
            ></wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectImplementationModel } from '@/modules/project-implementation/project-implementation-model';
import Templates from '@/modules/template/components/template-view-file-page';
import CooperationPartnerContractsListTable from '@/modules/cooperation-partner-contract/components/cooperation-partner-contract-list-table.vue';
import EmploymentContractListTable from '@/modules/employment-contract/components/employment-contract-list-table';
import ImplementationStatus from '@/shared/enums/implementationStatus';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';

const statusEnum = ImplementationStatus.values;
const { fields } = ProjectImplementationModel;
const formSchema = new FormSchema([
  fields.id,
  fields.ethicsCommitteeEnum,
  fields.ethicsCommitteeDecision,
  fields.cooperationContractsEnum,
  fields.cooperationPartnerContracts,
  fields.employmentContractsEnum,
  fields.employmentContracts,
  fields.status,
  fields.updatedAt,
]);

export default {
  name: 'app-project-implementation-form',

  props: [
    'isEditing',
    'record',
    'saveLoading',
    'modal',
    'allReleasedCC',
    'anyToRequestReleaseCC',
    'allReleasedEC',
    'anyToRequestReleaseEC',
    'isEthicsCommitteeEnumSet',
    'isCooperationContractsEnumSet',
    'isEmploymentContractsEnumSet',
  ],

  components: {
    [Templates.name]: Templates,
    [CooperationPartnerContractsListTable.name]: CooperationPartnerContractsListTable,
    [EmploymentContractListTable.name]: EmploymentContractListTable,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      isMounted: false,
      filterCoopPartnerContractBy: null,
      filterEmploymentContractBy: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  mounted() {
    this.isMounted = true;
  },

  watch: {
    'model.cooperationPartnerContracts': function(payload) {
      // when the parentId prop changes, this function will be fired.
      EventBus.$emit(
        'updateCooperationPartenerContractsList',
        payload,
      );
    },
    'model.employmentContracts': function(payload) {
      // when the parentId prop changes, this function will be fired.
      EventBus.$emit('updateEmploymentContractsList', payload);
    },
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch(
          'projectImplementation/form/doSetFormModel',
          this.model,
        );
      },
    },
    record: function() {
      this.doUpdateModel();
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      labelWidthFilter: 'layout/labelWidthFilter',
    }),

    nextButtonDisabled() {
      if (!this.isMounted) return;

      const step = this.$refs.formWizard.activeTabIndex;

      if (step === 0 && this.isEthicsCommitteeEnumSet) {
        return false;
      }
      if (step === 1 && this.isCooperationContractsEnumSet) {
        return false;
      }
      if (step === 0 && this.isEmploymentContractsEnumSet) {
        return false;
      }

      return true;
    },

    displayRequestRelease() {
      let status = false;
      if (this.activeTabIndex === 1) {
        status = this.model.cooperationContractsEnum === 'yes';
      } else if (this.activeTabIndex === 2) {
        status = this.model.employmentContractsEnum === 'yes';
      }
      return status;
    },

    isRequestBtnDisabled() {
      if (this.activeTabIndex === 1) {
        return !this.anyToRequestReleaseCC;
      } else if (this.activeTabIndex === 2) {
        return !this.anyToRequestReleaseEC;
      } else return false;
    },

    status() {
      return this.model.status;
    },

    isCompleted() {
      return this.status === statusEnum.completed;
    },

    ethicsCommitteeDecisionRequired() {
      return (
        this.model.ethicsCommitteeEnum === 'yes' &&
        this.isEthicsCommitteeEnumSet
      );
    },

    cooperationPartnerContractRequired() {
      return (
        this.model.cooperationContractsEnum === 'yes' &&
        this.isCooperationContractsEnumSet
      );
    },

    employmentContractRequired() {
      return (
        this.model.employmentContractsEnum === 'yes' &&
        this.isEmploymentContractsEnumSet
      );
    },

    fields() {
      return fields;
    },

    getWizardStartIndex() {
      switch (this.status) {
        case statusEnum.ethicsCommittee:
          return 0;
        case statusEnum.cooperationPartnerContracts:
          return 1;
        case statusEnum.employmentContracts:
        case statusEnum.completed:
          return 2;
        default:
          return 0;
      }
    },

    activeTabIndex() {
      return this.$refs.formWizard.activeTabIndex;
    },

    disabledFinishButton() {
      const noCooperationContracts =
        this.model.cooperationContractsEnum === 'no';
      const noEmploymentContracts =
        this.model.employmentContractsEnum === 'no';

      if (noCooperationContracts && noEmploymentContracts) {
        return false;
      } else if (noCooperationContracts && !noEmploymentContracts) {
        return !this.allReleasedEC;
      } else if (!noCooperationContracts && noEmploymentContracts) {
        return !this.allReleasedCC;
      } else if (!noCooperationContracts && !noEmploymentContracts) {
        return !(this.allReleasedEC && this.allReleasedCC);
      }
      return true;
    },
  },

  methods: {
    getTranslation(value) {
      return i18n(value);
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    isSameElement(el, index, arr) {
      if (index === 0) {
        return true;
      } else {
        return el.status === arr[index - 1].status;
      }
    },

    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doEmit() {
      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    //compare two Arrays
    equalsIgnoreOrder(a, b) {
      if (a.length !== b.length) return false;
      const uniqueValues = new Set([...a, ...b]);
      for (const v of uniqueValues) {
        const aCount = a.filter((e) => e === v).length;
        const bCount = b.filter((e) => e === v).length;
        if (aCount !== bCount) return false;
      }
      return true;
    },

    async doSubmit() {
      //status is finished so we don't have anything to do
      /* if (
        this.model.status === statusEnum.finished &&
        this.activeTabIndex != 5
      ) {
        return;
      } */

      if (this.status === statusEnum.completed) {
        return;
      }

      switch (this.activeTabIndex) {
        case 0:
          await this.validateEthicsCommitte();
          await this.setStatus(statusEnum.ethicsCommittee);
          break;
        case 1:
          await this.validateCooperationPartners();
          await this.setStatus(
            statusEnum.cooperationPartnerContracts,
          );
          break;
        case 2:
          await this.validateEmploymentContracts();
          await this.setStatus(statusEnum.employmentContracts);
          break;
        case 3:
          await this.validateGrants();
          await this.setStatus(statusEnum.grants);
          break;

        // completed is missings
        default:
          break;
      }

      await this.doEmit();
    },

    async setStatus(value) {
      if (this.model.status === statusEnum.completed) {
        return;
      }
      this.model.status = value;
    },

    async doSubmitFinish() {
      await this.setStatus(statusEnum.completed);
      await this.doEmit();
    },

    doRequestDraftsRelease() {
      let payload = null;
      let event = null;
      const id = this.model.id;

      //Cooperation Partner Draft Contracts
      if (this.activeTabIndex === 1) {
        payload = this.model.cooperationPartnerContracts;
        event = 'requestReleaseCoopPartnerContracts';
      }
      //Employment Draft Contracts
      if (this.activeTabIndex === 2) {
        payload = this.model.employmentContracts;
        event = 'requestReleaseEmploymentContracts';
      }

      const values = payload.map((f) => f.id);

      return this.$emit(event, {
        id,
        values,
      });
    },

    //filter cooperation partner contracts autocomplete after row has been deleted from table
    removeCoopPartnerContract(payload) {
      const filteredCoopPartner = this.model.cooperationPartnerContracts.filter(
        (f) => f.id != payload.id,
      );
      this.model.cooperationPartnerContracts = filteredCoopPartner;
      this.filterCoopPartnerContractBy = payload.id;
    },

    //filter employment contracts autocomplete after row has been deleted from table
    removeEmploymentContract(payload) {
      const filteredEmploymentPartner = this.model.employmentContracts.filter(
        (f) => f.id != payload.id,
      );
      this.model.employmentContracts = filteredEmploymentPartner;
      this.filterEmploymentContractBy = payload.id;
    },

    //filter employment contracts autocomplete after row has been deleted from table
    removeExternalApplication(payload) {
      const filteredGrant = this.model.grants.filter(
        (f) => f.id != payload.id,
      );
      this.model.grants = filteredGrant;
      this.filterGrantBy = payload.id;
    },

    async validateEthicsCommitte() {
      return this.$refs.formEthicsCommittee.validate();
    },

    async validateCooperationPartners() {
      return this.$refs.formCooperationPartners.validate();
    },

    async validateEmploymentContracts() {
      return this.$refs.formEmploymentContracts.validate();
    },

    async validateGrants() {
      return this.$refs.formGrants.validate();
    },
  },
};
</script>

<style scoped>
.wizard-btn {
  padding: 0px, 2px;
}
</style>
